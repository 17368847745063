<template>
  <div ref="wordCloudContainer" class="word-cloud-container"></div>
</template>

<script>
const d3 = require("d3")
const cloud = require("d3-cloud")

// Export the SFC
export default {
  // Name of the component
  name: "WordCloud",

  // Accept incoming data from parents
  props: {
    width: {
      type: Number,
      required: false,
      default: null
    },

    height: {
      type: Number,
      default: 300
    },

    words: {
      type: Array,
      required: true
    }
  },

  // Define local data variables
  data() {
    return {
      layout: null
    }
  },

  // Define the props
  methods: {
    /**
     * Draw the word cloud
     *
     * @param {Array} words
     * @returns {void}
     */
    draw(words) {
      d3.select(this.$refs.wordCloudContainer)
        .append("svg")
        .attr("width", this.layout.size()[0])
        .attr("height", this.layout.size()[1])
        .append("g")
        .attr("transform", "translate(" + this.layout.size()[0] / 2 + "," + this.layout.size()[1] / 2 + ")")
        .selectAll("text")
        .data(words)
        .enter()
        .append("text")
        .style("font-size", (d) => d.size + "px")
        .style("fill", (d) => d.color || "#000")
        .style("font-family", "Impact")
        .attr("text-anchor", "middle")
        .attr("transform", function (d) {
          return "translate(" + [d.x, d.y] + ")rotate(" + d.rotate + ")"
        })
        .text((d) => d.text)
    },

    /**
     * Clean the DOM element and D3 object
     *
     * @returns {void}
     */
    clean() {
      d3.select(this.$refs.wordCloudContainer).selectAll("*").remove()
    }
  },

  /**
   * Right after the component is first rendered
   *
   * @returns {void}
   */
  mounted() {
    this.layout = cloud()
      .size([this.width || this.$refs.wordCloudContainer.clientWidth, this.height])
      .words(this.words)
      .padding(5)
      .random(() => 0.5)
      .rotate(function (word) {
        // return (Math.floor(Math.random() * 3) - 1) * 90
        return (Math.floor(word.text.length * 3) - 1) * 90
      })
      .font("Impact")
      .fontSize((d) => d.size)
      .on("end", this.draw)

    this.layout.start()
  },

  /**
   * When the component is to be deleted
   *
   * @returns {void}
   */
  beforeDestroy() {
    this.clean()
  }
}
</script>
